.container {
    padding: 1rem;
}
.href {
    text-decoration: none;
    color: #ffffff;
}
.button {
    min-width: 100px;
    padding: 5px;
    background-color: #fe0072;
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 4px;
}

.buttonGroup {
    display: flex;
    min-width: 250px;
    justify-content: space-between;
}

.header {
    margin-bottom: 1rem;
    font-weight: 500;
}

.content {
    justify-content: center;
    align-items: center;
    display: flex;
}
