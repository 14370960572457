.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.field-group {
    margin: 10px auto;
}

.field-key {
    font-size: 13px;
    white-space: nowrap;
    opacity: 0.5;
}

.reset-password {
    font-size: 13px;
    color: #fe0072;
    text-decoration: underline;
    cursor: pointer;
}

.password-container {
    margin-bottom: 20px;
}

.password-box {
    display: inline-block;
    width: 210px;
}

.password-container input {
    height: 26px;
    width: 150px;
}

.password-validation-error {
    color: #fe0072;
    font-size: 12px;
}

.accounts-header {
    overflow: hidden;
    width: 100%;
    padding: 5px;
    margin-bottom: 20px;
}

.account-list-container {
    justify-content: center;
}

.account-list {
    justify-content: center;
    display: flex;
    min-height: 60vh;
}

.account-list-pagination {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
}

.account-list-item {
    cursor: pointer;
    margin: 12px;
    min-width: 600px;
    max-width: 600px;
    display: flex;
    padding: 15px;
}

.account-list-item-details {
    width: 100%;
}

.account-list-item-logo {
    margin: auto 20px;
}

.account-list-item-logo img {
    width: 70px;
    height: auto;
    max-height: 50px;
}

.account-list-item-actions {
    display: flex;
    flex-direction: column;
    width: 150px;
}

.account-type {
    opacity: 0.5;
    font-size: 11px;
}

.account-toggle {
    display: flex;
    justify-content: center;
}

.account-name {
    font-size: 22px;
}

.account-domain {
    opacity: 0.5;
    margin-top: 10px;
}

.accounts-header-actions {
    float: right;
    display: flex;
    justify-content: space-between;
}

.accounts-header-item {
    margin: auto 10px;
}

.field-value {
    font-size: 15px;
    white-space: nowrap;
}

.account-title {
    font-size: 40px;
}

.account-details-container {
    width: 800px;
    display: flex;
    justify-content: space-between;
}

.account-settings-container {
    width: 800px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.account-details-section {
    width: 100%;
}

.account-settings-section {
    width: 100%;
}

.line {
    display: contents;
}

.cq-container {
    justify-content: center;
    height: calc(100vh - 150px);
}

.networks-container {
    width: 100%;
}

.account-netwrok-heading {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.account-netwrok-heading button {
    margin-bottom: 10px;
    right: 0;
}

.network-details-container {
    width: 700px;
    display: flex;
    justify-content: space-between;
}

.network-details-section {
    width: 100%;
}

.network-action-container button {
    margin: 10px auto;
    width: 20%;
}

.network-action-container {
    display: flex;
}

.form-title {
    margin: 10px auto;
    font-size: 22px;
}

.notice {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-top: 8px;
    padding-bottom: 8px;
}

.form-actions {
    margin: 10px auto;
    font-size: 22px;
    display: flex;
    justify-content: center;
}

.cq-form-control {
    min-height: 38px;
    width: 100%;
    max-width: 200px;
}

.input-group {
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
    width: 500px;
}

.send-invite-btn {
    margin-right: 20px;
}

.cheq-info-block {
    margin-bottom: 20px;
}

.checkbox-parent {
    flex: 3;
}

.frequency-capping-container {
    padding: 1rem;
    border-radius: 4px;
}

.frequency-capping-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.frequency-input {
    text-align: center;
    width: 3rem !important;
}

.frequency-input-container {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.rule-row {
    margin-bottom: 2rem;
    background-color: #1c1f2c;
    border-radius: 4px;
}

.rule-row-inactive {
    opacity: 0.7;
}

.rule-content {
    padding: 0.8rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.campaign-content {
    background-color: #1f2231;
    padding: 0.8rem;
    padding-left: 1.6rem;
}

.frequency-select {
    width: 8rem;
    padding-left: 0.2rem;
    margin-left: 0.2rem;
}

.campaign-input {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.chip-wrapper {
    display: flex;
    flex-flow: wrap;
    max-width: 700px;
}

.chip-container {
    width: fit-content;
    padding-bottom: 1rem;
}

.MuiChip-root {
    max-width: 200px;
}

.button-group {
    padding: 0.8rem;
    display: flex;
}

.button {
    margin: 0.5rem 0.5rem;
    width: 6rem;
}

.rules-container {
    border: 1px solid red;
    overflow: scroll;
    height: 10rem;
}

.rule-row-blur {
    opacity: 0.7;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-fc-rules-container {
    width: 100%;
    border: 1px solid #fe0072;
    border-radius: 4px;
    margin-bottom: 7px;
    padding: 12px;
    box-sizing: border-box;
}

.add-fc-rules-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fc-input-disabled {
    color: #ffffff !important;
}

.user-conversion-input-disabled {
    color: #ffffff !important;
}

.table-toolbar {
    padding: 1em;
}

.add-account-btn {
    display: block;
    margin-left: auto;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

tbody td:hover {
    cursor: pointer !important;
}

.integrations-item {
    display: flex;
    flex-direction: column;
}