.container input{
    cursor: pointer;
    background-color: #fe0072;
    font-family: Avenir;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none
}

.disabled input {
    background-color: #A8A8A8;
    cursor: auto;
}
