.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    border: 1px solid #fe0072;
    border-radius: 5px;
}

p {
    font-size: 18px;
    margin: 0;
}

.flex-end {
    justify-content: flex-end;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bold {
    font-weight: 900;
}
