.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.app-load {
    padding-top: 20%;
    text-align: center;
    vertical-align: middle;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/*
/ CHEQ Styles , move later to sperated sheet
*/

.dialog-buttons-Container {
    justify-content: space-between;
    margin: 10px auto;
}

.dialog-buttons-Container button {
    margin: auto 10px;
}

.text-muted {
    opacity: 0.5;
}

.cq-form-control {
    margin: 10px auto;
}

body {
    background-color: #ebecf0;
}

/* Tooltip container */
.cq-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.cq-tooltip .cq-tooltiptext {
    visibility: hidden;
    min-width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.cq-tooltip:hover .cq-tooltiptext {
    visibility: visible;
}

.cq-container {
    display: flex;
    width: -webkit-fill-available;
    width: -moz-available;
}

.cq-heading {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 10px;
}

.greyed-out-input {
    background-color: lightgrey;
    opacity: 0.8;
    width: 212px;
    height: 36px;
}

.cq-btn {
    min-width: 100px;
    padding: 5px;
    background-color: #fe0072;
    color: #fff;
    border: none;
    cursor: pointer;
}

.cq-btn-lg {
    min-width: 100px;
    padding: 5px;
    background-color: #fe0072;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 22px;
}

.cq-btn-lg:disabled,
.cq-btn:disabled {
    background-color: #c6c6c6;
    cursor: auto;
}

.cq-btn.secondary {
    background-color: #c6c6c6;
    border: 1px solid #000;
    border-radius: 4px;
    color: #000;
}