@font-face{
    font-family: 'Avenir';
    src: url(./assets/fonts/avenir/AvenirLTStd-Black.otf) format('opentype');
  }
  
  @font-face{
    font-family: 'PingL';
    font-weight: bold;
    src: url(./assets/fonts/PingL/PingL-Regular.otf) format('opentype');
  }

  @font-face{
    font-family: 'PingLThin';
    src: url(./assets/fonts/PingL/PingL-Thin.otf) format('opentype');
  }

  @font-face{
    font-family: 'Avenir';
    font-style: oblique;
    src: url(./assets/fonts/avenir/AvenirLTStd-BlackOblique.otf) format('opentype');
  }
  
  @font-face{
    font-family: 'Avenir';
    font-weight: bold;
    src: url(./assets/fonts/avenir/AvenirLTStd-Heavy.otf) format('opentype');
  }
  
  @font-face{
    font-family: 'Avenir';
    font-weight: bold;
    font-style: oblique;
    src: url(./assets/fonts/avenir/AvenirLTStd-HeavyOblique.otf) format('opentype');
  }
  
  @font-face{
    font-family: 'Avenir';
    font-weight: lighter;
    src: url(./assets/fonts/avenir/AvenirLTStd-Light.otf) format('opentype');
  }
  
  @font-face{
    font-family: 'Avenir';
    font-weight: lighter;
    font-style: oblique;
    src: url(./assets/fonts/avenir/AvenirLTStd-LightOblique.otf) format('opentype');
  }