.container {
    margin-top: 20px;
    color: #fe0072;
    background-color: #14172c;
    font-weight: bold;
    display: inline-flex;
    padding-left: -30px;
    white-space: nowrap;
    right: 1%;
    top: 26px;
    position: absolute;
}
