.reportContainer {
    width: 100%;
    height: 85vh;
    margin: 20px;
}

.formContainer {
    margin: 20px;
}

.runBtn {
    width: 100%;
}

.noResultsText {
    margin: auto;
    padding-top: 300px;
    color: #000;
    text-align: center;
}
