.container {
  position: fixed;
  display : flex !important;
  height : 100% !important;
  width : 100% !important;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #fc5296;
  background-image: linear-gradient(45deg, #0D0D0D 0%, #fc5296 50%)

}
